<template>
    <el-dialog :close-on-click-modal="false"
               :title="form.id ? '编辑' : '新增'"
               :visible="true"
               width="800px"
               :before-close="close">
        <el-form :model="form"
                 ref="form"
                 :rules="rules"
                 label-width="120px"
                 :inline="false"
                 size="normal">
            <!-- <button @click="formdataUpload">sss</button> -->
            <el-form-item label="设备LOGO"
                          prop="empLogo">
                <el-upload class="upload-demo"
                           ref="upload"
                           name="file"
                           :headers="configHeader"
                           :action="getServiceUrl()"
                           :on-preview="handlePreview"
                           :on-remove="handleRemove"
                           :on-success="saveSuccess"
                           :on-change="handleChange"
                           :file-list="fileList"
                           :auto-upload="true"
                           :multiple="false"
                           :limit="1"
                           list-type="picture">
                    <el-button size="small"
                               v-show="fileList.length < 1"
                               type="primary">选择本地图片</el-button>
                </el-upload>
                <el-button size="small"
                           v-show="fileList.length < 1"
                           type="primary"
                           @click="chooseDefault">选择系统图片</el-button>

            </el-form-item>
            <el-form-item label="设备类型名称"
                          prop="empType">
                <el-input v-model="form.empType"
                          placeholder="请输入设备类型名称"
                          clearable />
            </el-form-item>
            <el-form-item label="赋码情况"
                          prop="haveCode">
                <el-radio-group v-model="form.haveCode"
                                :disabled="form.id"
                                @change="form.detect = ''">
                    <el-radio label="YES">赋码</el-radio>
                    <el-radio label="NO">不需要赋码</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="检测要求"
                          prop="detect"
                          v-if="form.haveCode === 'YES'">
                <el-radio-group v-model="form.detect">
                    <el-radio label="YES">需要检测</el-radio>
                    <el-radio label="NO">不需要检测</el-radio>
                </el-radio-group>
            </el-form-item>
            <!-- <label>设备字段</label> -->
            <el-form-item v-for="(domain, index) in form.typeFieldSaveActions"
                          :key="domain.key"
                          :label="'设备参数'+(index+1)">
                <div class="flex-start-center">

                    <!-- <el-form-item :prop="'typeFieldSaveActions.' + index + '.empField'"
                                  :rules="{required: true, message: '内容不能为空', trigger: 'blur'}">
                        <el-input style="width: 150px;margin-right: 15px;"
                                  placeholder="key"
                                  clearable
                                  v-model="domain.empField"></el-input>
                    </el-form-item> -->

                    <el-form-item :prop="'typeFieldSaveActions.' + index + '.empFieldName'"
                                  :rules="{required: true, message: '内容不能为空', trigger: 'blur'}">
                        <el-input style="width: 150px;margin-right: 15px;"
                                  placeholder="参数名称"
                                  clearable
                                  v-model="domain.empFieldName"></el-input>
                    </el-form-item>

                    <!-- <el-input style="width: 150px;margin-right: 15px;"
                              placeholder="请输入设备字段名称"
                              clearable
                              v-model="domain.empFieldName"></el-input> -->
                    <el-checkbox label="显示在列表"
                                 v-model="domain.listShow"></el-checkbox>
                    <el-checkbox label="是否必填"
                                 v-model="domain.isRequired"></el-checkbox>
                    <i @click.prevent="removeDomain(index)"
                       style="margin: 0 15px;cursor: pointer;"
                       class="el-icon-error"></i>
                </div>
            </el-form-item>
            <el-form-item>
                <el-button @click.prevent="addDomain">添加字段</el-button>
            </el-form-item>
            <el-form-item class="footer">
                <el-button type="danger"
                           @click="close">取消</el-button>
                <el-button type="primary"
                           v-loading="isloading"
                           @click="onSubmit">确认</el-button>
            </el-form-item>
        </el-form>
        <!-- 选择默认系统定义设备logo -->
        <popChooseDefaultLogo @close="closeSystemDialog"
                              @uploadLogo="formdataUpload"
                              v-if="chooseLogoVisible"></popChooseDefaultLogo>
    </el-dialog>
</template>
<script>
import baseManageApi from "@/api/baseManageApi.js";
import popChooseDefaultLogo from "./deviceTypeManage/popChooseDefaultLogo";
export default {
    name: "deviceType",
    props: {
        formData: {
            type: Object,
            default: null,
        },
    },
    components: {
        popChooseDefaultLogo,
    },
    watch: {
        formData: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                if (newVal) {
                    this.form = Object.assign(this.form, newVal, {
                        detect: newVal.detect && newVal.detect.name,
                        haveCode: newVal.haveCode && newVal.haveCode.name,
                    });
                    this.form.empLogo &&
                        this.fileList.push({
                            name: this.form.empLogo,
                            url: `${window.configPath.baseUrl}/file/view?file=${this.form.empLogo}&access-token=${this.token}`,
                        });
                    this.getExtendFiled();
                }
            },
        },
    },
    data() {
        return {
            token: this.commonJs.getCookie("ERPToken"),
            fileList: [],
            isloading: false,
            chooseLogoVisible: false, // 选择默认设备图片
            form: {
                empType: "", //设备类型
                empLogo: "", //设备logo
                detect: "", // 检测要求
                haveCode: "", // 是否赋码
                typeFieldSaveActions: [
                    {
                        empField: "",
                        empFieldName: "",
                        isRequired: true,
                        listShow: false,
                    },
                ],
            },
            rules: {
                empType: [
                    {
                        required: true,
                        message: "请输入设备类型名称",
                    },
                ],
                empLogo: [
                    {
                        required: true,
                        message: "请选择设备logo",
                    },
                ],
                detect: {
                    required: true,
                    message: "请验证检测要求",
                    trigger: "blur",
                },
                haveCode: {
                    required: true,
                    message: "请验证是否赋码",
                }, // 是否赋码
            },
        };
    },
    mounted() {},
    methods: {
        /* 获取当前设备拓展字段 */
        getExtendFiled() {
            baseManageApi.getEmpTypeId(this.form.id).then((res) => {
                this.form.typeFieldSaveActions = res.content;
            });
        },
        handlePreview() {},
        handleRemove() {
            this.fileList = [];
        },
        //获取本地服务器地址
        getServiceUrl() {
            return window.configPath.baseUrl + "/file/upload";
        },
        removeDomain(index) {
            this.form.typeFieldSaveActions.splice(index, 1);
        },
        addDomain() {
            this.form.typeFieldSaveActions.push({
                empField: "",
                empFieldName: "",
                isRequired: true,
                listShow: false,
            });
        },
        // 提交表单
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.createDeviceType();
                } else {
                    this.$message({
                        message: "请检查必填内容是否填写！",
                        type: "error",
                    });
                    return;
                }
            });
        },

        //新增设备类型
        createDeviceType() {
            this.isloading = true;
            // console.log(JSON.stringify(this.form));
            baseManageApi
                .saveDeviceType(this.form)
                .then(() => {
                    this.$message({ message: "新建成功", type: "success" });
                    this.isloading = false;
                    this.$emit("close");
                    this.$emit("refresh");
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        //上传成功
        saveSuccess(e) {
            this.form.empLogo = e.content.filename;
        },

        handleChange(file, fileList) {
            this.fileList = fileList;
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },

        /* 选择默认图片 */
        chooseDefault() {
            this.chooseLogoVisible = true;
        },

        //formdata上传图片  上传指定图片
        formdataUpload(logo) {
            console.log(logo);
            this.commonJs
                .loadImageToBlob(
                    this.commonJs.showFileImg(logo.id),
                    this.getServiceUrl(),
                    "file",
                    logo.name
                )
                .then((v) => {
                    this.form.empLogo = v.content.filename;
                    this.fileList = [
                        {
                            name: v.content.filename,
                            url: this.commonJs.showFileImg(logo.id),
                        },
                    ];
                });
        },

        // 新增或編輯系統彈框 关闭
        closeSystemDialog() {
            this.chooseLogoVisible = false;
        },
    },
    computed: {
        // 上传文件所需
        configHeader() {
            return { "access-token": this.commonJs.getCookie("ERPToken") };
        },
    },
};
</script>
<style scoped>
.upload-demo {
}
</style>