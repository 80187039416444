// 设备类型管理
<template>
    <div class="softwareInfoManage baseBg">
        <div class="searchBar ofh">
            <el-form :model="searchForm"
                     ref="searchForm"
                     class="fll"
                     :inline="true"
                     size="normal">
                <el-form-item label="设备类型">
                    <el-input v-model="searchForm.empType"
                              placeholder="请输入设备类型"
                              clearable />
                </el-form-item>

                <!-- <el-form-item label="赋码情况">
                    <selctMenu code="HaveCode"
                               v-model="searchForm.detectType"
                               placeholder="请选择赋码情况"
                               @input="(data) => searchForm.detectType = data"></selctMenu>
                </el-form-item> -->
                <el-form-item label="检测要求">
                    <el-select v-model="searchForm.detect"
                               placeholder="请选择检测要求"
                               clearable>
                        <el-option label="需要检测"
                                   value="YES"></el-option>
                        <el-option label="不需要检测"
                                   value="NO"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="赋码情况">
                    <el-select v-model="searchForm.haveCode"
                               placeholder="请选择赋码情况"
                               clearable>
                        <el-option label="赋码"
                                   value="YES"></el-option>
                        <el-option label="不需要赋码"
                                   value="NO"></el-option>
                    </el-select>
                </el-form-item>

                <el-button type="primary"
                           @click="search">搜索
                </el-button>
                <el-button type=""
                           @click="resetSearchForm">清除
                </el-button>
                <el-button type="success"
                           class="flr"
                           @click="addNew">新增设备类型
                </el-button>
            </el-form>
        </div>

        <div class="listBox">
            <div class="tableBox">
                <el-table :data="tableData"
                          :height="tableHeight"
                          v-loading="isloading"
                          border
                          stripe>
                    <el-table-column v-for="col in tableColumns"
                                     :prop="col.prop"
                                     :key="col.label"
                                     :label="col.label"
                                     align="center"
                                     :min-width="col.width">
                        <template slot-scope="scope">
                            <div v-if="col.prop === 'logo'">
                                <el-image @click="handleImgClick(`${PATH}/file/view/?file=${scope.row.empLogo}&access-token=${token}`)"
                                          style="width: 100px; height: 100px"
                                          fit="scale-down"
                                          :src="`${PATH}/file/view/?file=${scope.row.empLogo}&access-token=${token}`" />
                            </div>
                            <div v-else-if="col.prop === 'detect'">
                                <span class="green"
                                      v-if="scope.row[col.prop] && scope.row[col.prop].name === 'YES'">需要检测</span>
                                <span v-else>不需要检测</span>
                            </div>
                            <div v-else-if="col.prop === 'haveCode'">
                                <span v-if="scope.row[col.prop] && scope.row[col.prop].name === 'NO'">非赋码设备</span>
                                <span class="green"
                                      v-else>赋码设备</span>
                            </div>
                            <div v-else>{{ scope.row[col.prop] }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作"
                                     min-width="100">
                        <template slot-scope="scope">
                            <el-link type="primary"
                                     @click="details(scope.row)">查看
                            </el-link>
                            <el-link type="danger"
                                     @click="editTable(scope.row)">编辑
                            </el-link>
                            <el-popconfirm confirmButtonText='确认'
                                           cancelButtonText='取消'
                                           icon="el-icon-info"
                                           iconColor="red"
                                           @confirm="deleteTable(scope.row)"
                                           title="确定删除吗？">
                                <el-link slot="reference"
                                         type='danger'>删除
                                </el-link>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 查看大图 -->
                <el-image-viewer v-if="showViewer"
                                 :on-close="closeViewer"
                                 :url-list="viewerImgList" />
                <!-- 分页 -->
                <Pagination :page="searchForm.page"
                            :limit="searchForm.size"
                            :total="pageTotal"
                            @pagination="paginationChange" />
            </div>
        </div>
        <!-- 新增设备类型 -->
        <device-type-dialog v-if="dialogVisible"
                            :formData="dialogForm"
                            @close="closeSystemDialog"
                            @refresh="getList" />

    </div>
</template>

<script>
import baseManageApi from "@/api/baseManageApi.js";
import Pagination from "@/components/Pagination"; // 分页
import deviceTypeDialog from "./components/pop_deviceType";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
    name: "deviceTypeManage",
    props: [],

    components: {
        Pagination,
        deviceTypeDialog,
        ElImageViewer,
    },

    data() {
        return {
            showViewer: false, // 缩略图展示框是否显示

            viewerImgList: [], // 预览图片列表
            PATH: window.configPath.baseUrl,
            token: this.commonJs.getCookie("ERPToken"),
            dialogForm: null,
            dialogVisible: false,
            searchForm: {
                empType: null,
                detect: "", // 检测要求
                haveCode: "", // 赋码情况
                page: 1,
                size: 20,
            },
            options: [
                { value: "", label: "不需要检测" },
                { value: "", label: "需要检测" },
            ], // 检测要求
            pageTotal: 0,
            isloading: true,
            rowData: null,
            tableColumns: [
                {
                    prop: "empType",
                    label: "设备类型",
                    width: "100",
                },
                {
                    prop: "logo",
                    label: "设备logo",
                    width: "100",
                },
                {
                    prop: "haveCode",
                    label: "赋码情况",
                    width: "100",
                },
                {
                    prop: "detect",
                    label: "是否检测",
                    width: "100",
                },
            ],
            tableData: [],
        };
    },

    created() {},

    mounted() {
        this.search();
    },

    methods: {
        // 点击缩略图的方法
        handleImgClick(url) {
            this.showViewer = true;
            // let tempImgList = [url];
            // let temp = [];
            // for (let i = 0; i < index; i++) {
            //     temp.push(tempImgList.shift());
            // }
            // this.viewerImgList = tempImgList.concat(temp);
            this.viewerImgList = [url];
        },

        // 关闭预览图片
        closeViewer() {
            this.showViewer = false;
        },

        /* 获取列表 */
        getList(searchForm = this.searchForm) {
            this.isloading = true;
            baseManageApi
                .getDeviceTypeList(searchForm)
                .then((res) => {
                    this.isloading = false;
                    this.tableData = res.content.records;
                    this.pageTotal = res.content.total;
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        details(e) {
            this.$router.push({
                path: "/baseInfoManage/deviceModelManage",
                query: { deviceTypeInfo: JSON.stringify(e) },
            });
        },

        // 搜索
        search() {
            this.searchForm.page = 1;
            this.getList();
        },

        //清空筛选条件
        resetSearchForm() {
            this.searchForm.empType = "";
            this.searchForm.detect = "";
            this.searchForm.haveCode = "";
            this.getList();
        },

        // 新增
        addNew() {
            this.dialogVisible = true;
            this.dialogForm = null;
        },
        // 分页改变
        paginationChange(e) {
            this.getList(Object.assign(this.searchForm, e));
        },

        // 新增或編輯系統彈框 关闭
        closeSystemDialog() {
            this.dialogVisible = false;
        },

        // 表格的编辑操作
        editTable(e) {
            this.dialogVisible = true;
            this.dialogForm = JSON.parse(JSON.stringify(e));
        },
        // 表格的删除操作
        deleteTable(row) {
            this.$confirm("确认删除吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.isloading = true;
                baseManageApi
                    .deleteEmpType(row.id)
                    .then(() => {
                        this.$message({
                            message: "删除成功",
                            type: "success",
                        });
                        this.getList();
                    })
                    .catch(() => {
                        this.isloading = false;
                    });
            });
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 245 });
        },
    },

    watch: {},
};
</script>

<style lang='scss' scoped>
</style>
