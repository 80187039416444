<template>
    <el-dialog :close-on-click-modal="false"
               title="选择系统图片"
               :visible="true"
               width="600px"
               append-to-body
               :before-close="close">
        <el-form :model="form"
                 ref="form"
                 :rules="rules"
                 label-width="100px"
                 :inline="false"
                 size="normal">
            <el-row class="logoContainer">
                <el-image :class="['userIcon',chooseIndex === index ? 'active' : '']"
                          v-for="(item,index) in logoList"
                          :key="index"
                          @click="chooseLogoFn(item,index)"
                          :src="commonJs.showFileImg(item.id) || require('@/assets/imgs/default_userPhoto.png') "
                          fit="cover"
                          :lazy="true"></el-image>
            </el-row>
            <el-form-item class="footer">
                <el-button type="danger"
                           @click="close">取消</el-button>
                <el-button type="primary"
                           v-loading="isloading"
                           @click="save">确认</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script>
// import commonAPI from "@/api/commonAPI.js";

export default {
    name: "popChooseDefaultLogo",
    props: {},
    watch: {},
    data() {
        return {
            isloading: false,
            chooseIndex:null, //选中的图片索引值
            chooseLogo:null,//选中的图片对象
            logoList: [],
        };
    },
    mounted() {
        this.getAllLogo();
    },
    methods: {
        // 获取所有系统设备图片
        getAllLogo() {
            this.isloading = true;
            this.commonJs
                .getFileList("empManagerLogo")
                .then((v) => {
                    this.isloading = false;
                    this.logoList = v;
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        //选中的图片
        chooseLogoFn(logo,index){
            this.chooseLogo = logo;
            this.chooseIndex = index;
        },

        //确认
        save() {
            this.$emit("uploadLogo",this.chooseLogo);
            this.$emit("close");
        },

        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },
};
</script>
<style scoped>
.userIcon{
    width: 50px;
    margin: 10px;
    box-sizing: border-box;
    cursor: pointer;
}

.userIcon.active{
    outline: 1px solid red;
}
</style>